import { useState } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAuth } from '../lib/auth'
import SmallForm from '../components/SmallForm'
import ButtonPrimary from '../components/ButtonPrimary'
import LoadingOverlay from '../components/LoadingOverlay'

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
})

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const location = useLocation()
  const { apiToken, login } = useAuth()
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const { from } = location.state || { from: { pathname: '/' } }

  if (apiToken) {
    return <Redirect to={from} />
  }

  const onSubmit = ({ username, password }) => {
    setWorking(true)
    login(username, password)
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  return (
    <SmallForm>
      <h1 className="text-2xl mb-8">Welcome!</h1>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={working}></LoadingOverlay>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Username</label>
          <input
            type="text"
            {...register('username')}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.username?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Password</label>
          <input
            type="password"
            {...register('password')}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.password?.message}
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <ButtonPrimary text="Login" type="submit"></ButtonPrimary>
          {error && <span className="text-sm text-red-400">{error}</span>}
        </div>
      </form>
    </SmallForm>
  )
}

export default Login
