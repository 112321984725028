import { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { sortBy } from 'lodash'
import { AsyncResourceContent } from 'use-async-resource'
import {
  useVehicleOemTokens,
  useOrganization,
  useVehicleOem,
} from '../lib/apiHooks'
import Error from '../components/Error'
import Loading from '../components/Loading'
import ButtonPrimary from '../components/ButtonPrimary'
import Section from '../components/Section'
import OrganizationHeader from '../components/OrganizationHeader'
import TokenList from '../components/TokenList'
import BreadCrumb from '../components/Breadcrumb'
import LoadingOverlay from '../components/LoadingOverlay'
import { clearVehicleOemTokens } from '../lib/apiHooks'

function VehicleOemTokensPage() {
  const { id, vehicleOemId } = useParams()

  const [readTokens, refetchTokens] = useVehicleOemTokens(vehicleOemId)
  const [readOrganization] = useOrganization(id)
  const [readVehicleOem] = useVehicleOem(id, vehicleOemId)

  return (
    <div>
      <OrganizationHeader active="organization"></OrganizationHeader>
      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <VehicleOemTokens
            readTokens={readTokens}
            readOrganization={readOrganization}
            readVehicleOem={readVehicleOem}
            organizationId={id}
            vehicleOemId={vehicleOemId}
            refetchTokens={refetchTokens}
          ></VehicleOemTokens>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function VehicleOemTokens({
  readTokens,
  readOrganization,
  readVehicleOem,
  organizationId,
  vehicleOemId,
  refetchTokens,
}) {
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const tokens = readTokens()
  const organization = readOrganization()
  const vehicleOem = readVehicleOem()

  const filteredTokens = sortBy(
    tokens.filter((t) => t.vehicleOemId === parseInt(vehicleOemId, 10)),
    (i) => i.createdTimestamp
  ).reverse()

  const breadcrumbItems = [
    {
      title: organization.name,
      url: `/organizations/${organization.id}`,
    },
    {
      title: vehicleOem.cccId + ' - DKS Access Tokens',
    },
  ]

  const handleClick = () => {
    setWorking(true)
    axios
      .post(`/admin/api/v1/vehicle-oem/${vehicleOemId}/client-access-token`)
      .then(() => {
        clearVehicleOemTokens()
        refetchTokens(vehicleOemId)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  return (
    <div>
      <LoadingOverlay visible={working}></LoadingOverlay>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb items={breadcrumbItems}></BreadCrumb>
        <ButtonPrimary
          onClick={handleClick}
          text={`Create Token`}
        ></ButtonPrimary>
      </div>
      <TokenList
        organizationId={organizationId}
        tokens={filteredTokens}
      ></TokenList>
      {error && <span className="text-sm text-red-400">{error}</span>}
    </div>
  )
}

export default VehicleOemTokensPage
