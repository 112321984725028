import { useAuth } from '../lib/auth'
import { LogoutIcon } from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom'

function Header() {
  const { apiToken, logout } = useAuth()
  const history = useHistory()

  return (
    <div className="w-full border-white border-opacity-20 border-b flex-shrink relative py-4">
      <div className="flex justify-between">
        <div className="relative flex items-center space-x-3">
          <span
            onClick={() => history.push('/')}
            className="text-header text-5xl font-bold hover:text-header-hover transition-colors cursor-pointer"
          >
            DENSO
          </span>
          <span className="text-header font-thin text-3xl italic">
            digital key service
          </span>
        </div>
        {apiToken && (
          <LogoutIcon
            onClick={logout}
            className="h-8 w-8 text-header hover:text-header-hover transition-colors self-center cursor-pointer"
          />
        )}
      </div>
    </div>
  )
}

export default Header
