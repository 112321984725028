import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SmallForm from '../components/SmallForm'
import ButtonPrimary from '../components/ButtonPrimary'
import ButtonCancel from '../components/ButtonCancel'
import LoadingOverlay from '../components/LoadingOverlay'
import { clearOrganizationUsersCache } from '../lib/apiHooks'

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
})

function CreateAccount() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const { id } = useParams()
  const history = useHistory()
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const onSubmit = ({ username, password }) => {
    setWorking(true)
    axios
      .post(`/admin/api/v1/organization/${id}/account`, {
        username,
        password,
        isAdmin: false,
      })
      .then(() => {
        clearOrganizationUsersCache()
        history.push(`/organizations/${id}/users`)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  const cancel = (e) => {
    e.preventDefault()
    history.push(`/organizations/${id}/users`)
  }

  return (
    <SmallForm>
      <h1 className="text-2xl mb-8">Create User</h1>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={working}></LoadingOverlay>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Username</label>
          <input
            type="text"
            {...register('username')}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.username?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Password</label>
          <input
            type="password"
            {...register('password')}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.password?.message}
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <ButtonPrimary text="Create" type="submit"></ButtonPrimary>
          <ButtonCancel onClick={cancel}></ButtonCancel>
          {error && <span className="text-sm text-red-400">{error}</span>}
        </div>
      </form>
    </SmallForm>
  )
}

export default CreateAccount
