import VehicleOemItem from './VehicleOemItem'

function VehicleOemList({ organizationId, vehicleOems }) {
  return (
    <section>
      {vehicleOems.map((i) => (
        <VehicleOemItem
          key={i.id}
          organizationId={organizationId}
          vehicleOem={i}
        ></VehicleOemItem>
      ))}
      {vehicleOems.length === 0 && <div className="p-6">No Vehicle OEMs</div>}
    </section>
  )
}

export default VehicleOemList
