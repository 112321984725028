import UserItem from './UserItem'

function UserList({ users }) {
  return (
    <section>
      {users.map((i) => (
        <UserItem key={i.id} user={i}></UserItem>
      ))}
      {users.length === 0 && <div className="p-6 pt-0">No Users</div>}
    </section>
  )
}

export default UserList
