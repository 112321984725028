import { useParams, useHistory } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'

function OrganizationHeaderComponent(props) {
  return (
    <AsyncResourceContent
      fallback={
        <div className="m-6 text-gray-300 font-light text-lg">Loading...</div>
      }
      errorMessage={(error) => <div>{error.message}</div>}
    >
      <OrganizationHeader {...props}></OrganizationHeader>
    </AsyncResourceContent>
  )
}

function OrganizationHeader({ active, readCurrentUser }) {
  const { id } = useParams()
  const history = useHistory()

  return (
    <div className="my-6 flex space-x-4">
      <span
        onClick={() => history.push(`/organizations/${id}`)}
        className="text-gray-300 font-light text-lg hover:text-header-hover transition-colors cursor-pointer"
        style={{ color: active === 'organization' ? 'white' : null }}
      >
        Vehicle OEMs
      </span>
      <span
        onClick={() => history.push(`/organizations/${id}/users`)}
        className="text-gray-300 font-light text-lg hover:text-header-hover transition-colors cursor-pointer"
        style={{ color: active === 'users' ? 'white' : null }}
      >
        Users
      </span>
    </div>
  )
}

export default OrganizationHeaderComponent
