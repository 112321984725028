import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './lib/axiosConfig'
import { ProvideAuth } from './lib/auth'
import { PrivateRoute } from './components/PrivateRoute'
import Login from './pages/Login'
import Home from './pages/Home'
import Organization from './pages/Organization'
import Users from './pages/Users'
import DeviceOems from './pages/DeviceOems'
import Background from './components/Background'
import Header from './components/Header'
import CreateAccount from './pages/CreateAccount'
import UpdateAccount from './pages/UpdateAccount'
import VehicleOem from './pages/VehicleOem'
import DeviceOem from './pages/DeviceOem'
import CreateSigningKey from './pages/CreateSigningKey'
import UpdateSigningKey from './pages/UpdateSigningKey'
import CreateEncryptionKey from './pages/CreateEncryptionKey'
import UpdateEncryptionKey from './pages/UpdateEncryptionKey'
import CreateExternalCA from './pages/CreateExternalCA'
import UpdateExternalCA from './pages/UpdateExternalCA'
import CreateVehicleOem from './pages/CreateVehicleOem'
import UpdateVehicleOem from './pages/UpdateVehicleOem'
import CreateOrganization from './pages/CreateOrganization'
import CreateDeviceOem from './pages/CreateDeviceOem'
import VehicleOemTokens from './pages/VehicleOemTokens'
import DeviceOemTokens from './pages/DeviceOemTokens'
import Footer from './components/Footer'

function App() {
  return (
    <div className="w-full text-gray-600 bg-bg min-h-screen grid grid-rows-layout">
      <Background></Background>
      <div className="2xl:container 2xl:mx-auto px-8 pb-8 flex flex-col relative">
        <ProvideAuth>
          <Router>
            <Header></Header>
            <Switch>
              <Route path="/login">
                <Login></Login>
              </Route>
              <PrivateRoute path="/organizations/:id/users/create">
                <CreateAccount></CreateAccount>
              </PrivateRoute>
              <PrivateRoute path="/device-oems/create">
                <CreateDeviceOem></CreateDeviceOem>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/users/:userId/edit">
                <UpdateAccount></UpdateAccount>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/create">
                <CreateVehicleOem></CreateVehicleOem>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/tokens">
                <VehicleOemTokens></VehicleOemTokens>
              </PrivateRoute>
              <PrivateRoute path="/device-oems/:deviceOemId/tokens">
                <DeviceOemTokens></DeviceOemTokens>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/edit">
                <UpdateVehicleOem></UpdateVehicleOem>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId/signing-key/create">
                <CreateSigningKey></CreateSigningKey>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId/signing-key/edit">
                <UpdateSigningKey></UpdateSigningKey>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId/encryption-key/create">
                <CreateEncryptionKey></CreateEncryptionKey>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId/encryption-key/edit">
                <UpdateEncryptionKey></UpdateEncryptionKey>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId/external-ca/create">
                <CreateExternalCA></CreateExternalCA>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId/external-ca/edit">
                <UpdateExternalCA></UpdateExternalCA>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId/device-oems/:deviceOemId">
                <DeviceOem></DeviceOem>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/vehicle-oems/:vehicleOemId">
                <VehicleOem></VehicleOem>
              </PrivateRoute>
              <PrivateRoute path="/organizations/create">
                <CreateOrganization></CreateOrganization>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id/users">
                <Users></Users>
              </PrivateRoute>
              <PrivateRoute path="/device-oems">
                <DeviceOems></DeviceOems>
              </PrivateRoute>
              <PrivateRoute path="/organizations/:id">
                <Organization></Organization>
              </PrivateRoute>
              <PrivateRoute exact path="/">
                <Home></Home>
              </PrivateRoute>
            </Switch>
          </Router>
        </ProvideAuth>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default App
