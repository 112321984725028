function ButtonPrimary({ text, type, ...rest }) {
  return (
    <button
      type={type}
      className="outline-none focus:outline-none border border-primary bg-primary hover:bg-primary-hover 
        transition-colors text-white font-normal py-2 px-8 rounded shadow-sm"
      {...rest}
    >
      {text}
    </button>
  )
}

export default ButtonPrimary
