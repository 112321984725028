import { useParams, Link } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import { useOrganization, useOrganizationUsers } from '../lib/apiHooks'
import OrganizationHeader from '../components/OrganizationHeader'
import ButtonPrimary from '../components/ButtonPrimary'
import Section from '../components/Section'
import UserList from '../components/UserList'
import Error from '../components/Error'
import Loading from '../components/Loading'
import BreadCrumb from '../components/Breadcrumb'

function UsersPage({ user }) {
  const { id } = useParams()
  const [readOrganization] = useOrganization(id)
  const [readUsers] = useOrganizationUsers(id)

  return (
    <div>
      <OrganizationHeader active="users"></OrganizationHeader>

      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <Users
            readOrganization={readOrganization}
            readUsers={readUsers}
            organizationId={id}
          ></Users>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function Users({ readOrganization, readUsers, organizationId }) {
  const users = readUsers()
  const org = readOrganization()

  return (
    <>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb items={[{ title: org.name + ' - Users' }]}></BreadCrumb>
        <Link to={`/organizations/${organizationId}/users/create`} className="">
          <ButtonPrimary text="New User"></ButtonPrimary>
        </Link>
      </div>
      <UserList users={users}></UserList>
    </>
  )
}

export default UsersPage
