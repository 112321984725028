import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SmallForm from '../components/SmallForm'
import ButtonPrimary from '../components/ButtonPrimary'
import ButtonCancel from '../components/ButtonCancel'
import Error from '../components/Error'
import Loading from '../components/Loading'
import LoadingOverlay from '../components/LoadingOverlay'
import {
  useOrganizationUser,
  clearOrganizationUserCache,
} from '../lib/apiHooks'

const schema = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string(),
})

const safePassword = (password) => {
  if (!password || !password.trim()) return undefined
  return password
}

function UpdateAccountPage() {
  const { id, userId } = useParams()

  const [readUser] = useOrganizationUser(id, userId)

  return (
    <SmallForm>
      <AsyncResourceContent
        fallback={<Loading></Loading>}
        errorMessage={(error) => <Error error={error}></Error>}
      >
        <UpdateAccount readUser={readUser} organizationId={id}></UpdateAccount>
      </AsyncResourceContent>
    </SmallForm>
  )
}

function UpdateAccount({ readUser, organizationId }) {
  const user = readUser()
  const history = useHistory()
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(user)
  }, [user, reset])

  const onSubmit = ({ username, password }) => {
    setWorking(true)
    axios
      .put(`/admin/api/v1/organization/${organizationId}/account/${user.id}`, {
        username,
        password: safePassword(password),
        isAdmin: false,
      })
      .then(() => {
        clearOrganizationUserCache()
        history.push(`/organizations/${organizationId}/users`)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  const cancel = (e) => {
    e.preventDefault()
    history.push(`/organizations/${organizationId}/users`)
  }

  return (
    <>
      <h1 className="text-2xl mb-8">Edit User</h1>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={working}></LoadingOverlay>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Username</label>
          <input
            type="text"
            {...register('username')}
            defaultValue={user.username}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.username?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Password</label>
          <input
            type="password"
            {...register('password')}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.password?.message}
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <ButtonPrimary text="Save" type="submit"></ButtonPrimary>
          <ButtonCancel onClick={cancel}></ButtonCancel>
          {error && <span className="text-sm text-red-400">{error}</span>}
        </div>
      </form>
    </>
  )
}

export default UpdateAccountPage
