import { KeyIcon } from '@heroicons/react/outline'

function TokenListItem({ token }) {
  return (
    <div className="border-b last:border-b-0 group transition-colors text-gray-500 hover:text-primary">
      <div className="flex flex-1 flex-row center justify-between items-center px-4 py-6">
        <div className="flex flex-1 flex-row center">
          <KeyIcon className="h-6 w-6 mr-4"></KeyIcon>
          <h2 className="">{token.token}</h2>
        </div>
      </div>
    </div>
  )
}

export default TokenListItem
