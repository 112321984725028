import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Error from '../components/Error'
import Loading from '../components/Loading'
import SmallForm from '../components/SmallForm'
import ButtonPrimary from '../components/ButtonPrimary'
import ButtonCancel from '../components/ButtonCancel'
import LoadingOverlay from '../components/LoadingOverlay'
import { clearVehicleOemKeysCache } from '../lib/apiHooks'

const schema = yup.object().shape({
  secureKeyPem: yup.string().required('Secure Key is required'),
  certificatePem: yup.string().required('Certificate is required'),
})

function CreateSigningKeyPage() {
  const { id, vehicleOemId, deviceOemId } = useParams()

  return (
    <SmallForm>
      <AsyncResourceContent
        fallback={<Loading></Loading>}
        errorMessage={(error) => <Error error={error}></Error>}
      >
        <CreateSigningKey
          organizationId={id}
          vehicleOemId={vehicleOemId}
          deviceOemId={deviceOemId}
        ></CreateSigningKey>
      </AsyncResourceContent>
    </SmallForm>
  )
}

function CreateSigningKey({ organizationId, vehicleOemId, deviceOemId }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const history = useHistory()
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const onSubmit = ({ secureKeyPem, certificatePem }) => {
    setWorking(true)
    axios
      .post(`/admin/api/v1/vehicle-oem/${vehicleOemId}/signing-key`, {
        deviceOemId,
        secureKeyPem,
        certificatePem,
      })
      .then(() => {
        clearVehicleOemKeysCache()
        history.push(
          `/organizations/${organizationId}/vehicle-oems/${vehicleOemId}/device-oems/${deviceOemId}`
        )
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  const cancel = (e) => {
    e.preventDefault()
    history.push(
      `/organizations/${organizationId}/vehicle-oems/${vehicleOemId}/device-oems/${deviceOemId}`
    )
  }

  return (
    <>
      <h1 className="text-2xl mb-8">Create Signing Key</h1>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={working}></LoadingOverlay>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">
            Certificate
          </label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-64"
            {...register('certificatePem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.certificatePem?.message}
          </div>
        </div>
        <div className="">
          <label className="text-gray-500 text-sm mb-1 block">Secure Key</label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-36"
            {...register('secureKeyPem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.secureKeyPem?.message}
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <ButtonPrimary text="Create" type="submit"></ButtonPrimary>
          <ButtonCancel onClick={cancel}></ButtonCancel>
          {error && <span className="text-sm text-red-400">{error}</span>}
        </div>
      </form>
    </>
  )
}

export default CreateSigningKeyPage
