import { Link } from 'react-router-dom'
import { ChevronRightIcon, UserIcon } from '@heroicons/react/outline'

function UserItem({ user }) {
  return (
    <div className="cursor-pointer border-b last:border-b-0 transition-colors text-gray-500 hover:text-primary group">
      <Link
        to={`/organizations/${user.organizationId}/users/${user.id}/edit`}
        className=""
      >
        <div className="flex flex-1 flex-row center justify-between items-center px-4 py-6">
          <div className="flex flex-1 flex-row center">
            <UserIcon className="h-6 w-6 mr-4 "></UserIcon>
            <h2 className="">{user.username}</h2>
          </div>
          <ChevronRightIcon className="h-6 w-6 text-gray-400 group-hover:text-primary"></ChevronRightIcon>
        </div>
      </Link>
    </div>
  )
}

export default UserItem
