import { useParams, Link } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import {
  useDeviceOems,
  useOrganization,
  useVehicleOem,
  useVehicleOemKeys,
} from '../lib/apiHooks'
import Error from '../components/Error'
import Loading from '../components/Loading'
import ButtonPrimary from '../components/ButtonPrimary'
import Section from '../components/Section'
import OrganizationHeader from '../components/OrganizationHeader'
import DeviceOemList from '../components/DeviceOemList'
import BreadCrumb from '../components/Breadcrumb'

function VehicleOemPage() {
  const { id, vehicleOemId } = useParams()

  const [readOrganization] = useOrganization(id)
  const [readVehicleOem] = useVehicleOem(id, vehicleOemId)
  const [readVehicleOemKeys] = useVehicleOemKeys(vehicleOemId)
  const [readDeviceOems] = useDeviceOems()

  return (
    <div>
      <OrganizationHeader active="organization"></OrganizationHeader>
      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <VehicleOem
            readOrganization={readOrganization}
            readVehicleOem={readVehicleOem}
            readDeviceOems={readDeviceOems}
            readVehicleOemKeys={readVehicleOemKeys}
            organizationId={id}
            vehicleOemId={vehicleOemId}
          ></VehicleOem>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function VehicleOem({
  readOrganization,
  readVehicleOem,
  readVehicleOemKeys,
  readDeviceOems,
  organizationId,
  vehicleOemId,
}) {
  const organization = readOrganization()
  const vehicleOem = readVehicleOem()
  const deviceOems = readDeviceOems()

  return (
    <>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb
          items={[
            {
              title: organization.name,
              url: `/organizations/${organization.id}`,
            },
            {
              title: vehicleOem.cccId + ' - Device OEMs',
            },
          ]}
        ></BreadCrumb>
        <div className="flex flex-1 flex-row center justify-end items-center">
          <Link
            to={`/organizations/${organizationId}/vehicle-oems/${vehicleOemId}/tokens`}
            className="mr-4"
          >
            <ButtonPrimary text={`Manage Tokens`}></ButtonPrimary>
          </Link>
          <Link
            to={`/organizations/${organizationId}/vehicle-oems/${vehicleOemId}/edit`}
            className=""
          >
            <ButtonPrimary text={`Edit ${vehicleOem.cccId}`}></ButtonPrimary>
          </Link>
        </div>
      </div>
      <DeviceOemList
        readVehicleOemKeys={readVehicleOemKeys}
        organizationId={organizationId}
        vehicleOemId={vehicleOemId}
        deviceOems={deviceOems}
      ></DeviceOemList>
    </>
  )
}

export default VehicleOemPage
