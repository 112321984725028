import {
  ChevronRightIcon,
  DeviceMobileIcon,
  CheckIcon,
} from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

function DeviceOemItem({
  vehicleOemId,
  organizationId,
  deviceOem,
  readVehicleOemKeys,
}) {
  const { signingKeys, externalCAs, encryptionKeys } = readVehicleOemKeys()

  const signingKey = signingKeys.find((k) => k.deviceOemId === deviceOem.id)
  const externalCA = externalCAs.find((k) => k.deviceOemId === deviceOem.id)
  const encryptionKey = encryptionKeys.find(
    (k) => k.deviceOemId === deviceOem.id
  )
  const isAllSet =
    !!signingKey?.secureKeyPem &&
    !!encryptionKey?.secureKeyPem &&
    !!externalCA?.certificatePem

  const notSet = []
  if (!signingKey?.secureKeyPem) notSet.push('No Signing Key')
  if (!encryptionKey?.secureKeyPem) notSet.push('No Encryption Key')
  if (!externalCA?.certificatePem) notSet.push('No External CA')

  const notSetDisplay = `(${notSet.join(', ')})`

  return (
    <div className="cursor-pointer border-b last:border-b-0 group transition-colors text-gray-500 hover:text-primary">
      <Link
        to={`/organizations/${organizationId}/vehicle-oems/${vehicleOemId}/device-oems/${deviceOem.id}`}
        className=""
      >
        <div className="flex flex-1 flex-row center justify-between items-center px-4 py-6 ">
          <div className="flex flex-1 flex-row center">
            <DeviceMobileIcon className="h-6 w-6 mr-4"></DeviceMobileIcon>
            <h2 className="">{deviceOem.cccId}</h2>
            <div className="ml-4 flex items-center">
              {isAllSet ? (
                <CheckIcon className="h-6 w-6 text-green-500"></CheckIcon>
              ) : (
                <p className="text-gray-400 text-sm">{notSetDisplay}</p>
              )}
            </div>
          </div>

          <ChevronRightIcon className="h-6 w-6 text-gray-400 group-hover:text-primary"></ChevronRightIcon>
        </div>
      </Link>
    </div>
  )
}

export default DeviceOemItem
