import { Link } from 'react-router-dom'
import { ChevronRightIcon } from '@heroicons/react/outline'

function BreadCrumb({ items }) {
  return (
    <div className="flex flex-row">
      {items.map(({ title, url }, index) => (
        <div key={title} className="flex flex-row items-center">
          {!!url ? (
            <Link to={url} className="hover:text-primary">
              <h1 className="text-2xl py-2">{title}</h1>
            </Link>
          ) : (
            <h1 className="text-2xl py-2">{title}</h1>
          )}
          {index < items.length - 1 && (
            <ChevronRightIcon className="h-6 w-6 text-gray-400 mx-2"></ChevronRightIcon>
          )}
        </div>
      ))}
    </div>
  )
}

export default BreadCrumb
