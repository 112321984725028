import { useParams } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import {
  useDeviceOems,
  useOrganization,
  useVehicleOem,
  useVehicleOemKeys,
} from '../lib/apiHooks'
import Error from '../components/Error'
import Loading from '../components/Loading'
import Section from '../components/Section'
import OrganizationHeader from '../components/OrganizationHeader'
import KeyItem from '../components/KeyItem'
import BreadCrumb from '../components/Breadcrumb'

function DeviceOemPage() {
  const { id, vehicleOemId, deviceOemId } = useParams()

  const [readOrganizations] = useOrganization(id)
  const [readVehicleOem] = useVehicleOem(id, vehicleOemId)
  const [readVehicleOemKeys] = useVehicleOemKeys(vehicleOemId)
  const [readDeviceOems] = useDeviceOems()

  return (
    <div>
      <OrganizationHeader active="organization"></OrganizationHeader>
      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <DeviceOem
            readOrganizations={readOrganizations}
            readVehicleOem={readVehicleOem}
            readDeviceOems={readDeviceOems}
            readVehicleOemKeys={readVehicleOemKeys}
            deviceOemId={deviceOemId}
          ></DeviceOem>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function DeviceOem({
  readOrganizations,
  readVehicleOem,
  readDeviceOems,
  readVehicleOemKeys,
  deviceOemId,
}) {
  const organization = readOrganizations()
  const vehicleOem = readVehicleOem()
  const deviceOems = readDeviceOems()
  const { signingKeys, externalCAs, encryptionKeys } = readVehicleOemKeys()

  const deviceOem = deviceOems.find((d) => d.id === parseInt(deviceOemId, 10))
  const signingKey = signingKeys.find((k) => k.deviceOemId === deviceOem.id)
  const externalCA = externalCAs.find((k) => k.deviceOemId === deviceOem.id)
  const encryptionKey = encryptionKeys.find(
    (k) => k.deviceOemId === deviceOem.id
  )

  return (
    <>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb
          items={[
            {
              title: organization.name,
              url: `/organizations/${organization.id}`,
            },
            {
              title: vehicleOem.cccId,
              url: `/organizations/${organization.id}/vehicle-oems/${vehicleOem.id}`,
            },
            { title: deviceOem.cccId },
          ]}
        ></BreadCrumb>
      </div>
      <div>
        <KeyItem
          organizationId={organization.id}
          vehicleOemId={vehicleOem.id}
          deviceOemId={deviceOem.id}
          keyPath="signing-key"
          keyName="Signing Key PEM (Q)"
          isSet={!!signingKey?.secureKeyPem}
        ></KeyItem>
        <KeyItem
          organizationId={organization.id}
          vehicleOemId={vehicleOem.id}
          deviceOemId={deviceOem.id}
          keyPath="encryption-key"
          keyName="Encryption Key PEM (P)"
          isSet={!!encryptionKey?.secureKeyPem}
        ></KeyItem>
        <KeyItem
          organizationId={organization.id}
          vehicleOemId={vehicleOem.id}
          deviceOemId={deviceOem.id}
          keyPath="external-ca"
          keyName="External CA PEM (F)"
          isSet={!!externalCA?.certificatePem}
        ></KeyItem>
      </div>
    </>
  )
}

export default DeviceOemPage
