import axios from 'axios'

axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('apiToken')

    if (token) {
      config.headers = {
        authorization: token,
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)
