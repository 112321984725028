import React, { useEffect } from 'react'
import axios from 'axios'
import { Redirect, useLocation, Route } from 'react-router-dom'
import { useAuth } from '../lib/auth'

export const PrivateRoute = ({ children, ...rest }) => {
  const { apiToken, logout } = useAuth()
  const location = useLocation()

  useEffect(() => {
    axios.get('/admin/api/v1/session').catch(() => {
      logout()
    })
  }, [apiToken, logout])

  if (!apiToken) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />
  }

  return <Route {...rest}>{children}</Route>
}
