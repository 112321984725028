import { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SmallForm from '../components/SmallForm'
import ButtonPrimary from '../components/ButtonPrimary'
import ButtonCancel from '../components/ButtonCancel'
import LoadingOverlay from '../components/LoadingOverlay'
import { clearVehicleOemCache } from '../lib/apiHooks'

const schema = yup.object().shape({
  cccId: yup.string().required('CCC ID is required'),
  certificatePem: yup.string().required('Certificate is required'),
  secureKeyPem: yup.string().required('Secure Key is required'),
  publicKeyPem: yup.string().required('Public Key is required'),
  ktsAccessKey: yup.string().required('KTS Access Key is required'),
})

function CreateVehicleOem() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })
  const { id } = useParams()
  const history = useHistory()
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const onSubmit = ({
    cccId,
    certificatePem,
    secureKeyPem,
    publicKeyPem,
    ktsAccessKey,
  }) => {
    setWorking(true)
    axios
      .post(`/admin/api/v1/organization/${id}/vehicle-oem`, {
        cccId,
        certificatePem,
        secureKeyPem,
        publicKeyPem,
        ktsAccessKey,
      })
      .then(({ data }) => {
        clearVehicleOemCache()
        history.push(`/organizations/${id}/vehicle-oems/${data.id}/key`)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  const cancel = (e) => {
    e.preventDefault()
    history.push(`/organizations/${id}`)
  }

  return (
    <SmallForm>
      <h1 className="text-2xl mb-8">Create Vehicle OEM</h1>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={working}></LoadingOverlay>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">CCC ID</label>
          <input
            type="text"
            {...register('cccId')}
            className="outline-none border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3"
          />
          <div className="text-sm text-red-400 mt-1">
            {errors.cccId?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Public Key</label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-24"
            {...register('publicKeyPem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.publicKeyPem?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Secure Key</label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-36"
            {...register('secureKeyPem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.secureKeyPem?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">
            Certificate
          </label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-64"
            {...register('certificatePem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.certificatePem?.message}
          </div>
        </div>
        <div className="">
          <label className="text-gray-500 text-sm mb-1 block">
            KTS Access Key
          </label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-16"
            {...register('ktsAccessKey')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.ktsAccessKey?.message}
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <ButtonPrimary text="Create" type="submit"></ButtonPrimary>
          <ButtonCancel onClick={cancel}></ButtonCancel>
          {error && <span className="text-sm text-red-400">{error}</span>}
        </div>
      </form>
    </SmallForm>
  )
}

export default CreateVehicleOem
