import { ChevronRightIcon, KeyIcon, CheckIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

function KeyItem({
  organizationId,
  vehicleOemId,
  deviceOemId,
  isSet,
  keyPath,
  keyName,
}) {
  return (
    <div className="cursor-pointer border-b last:border-b-0 group transition-colors text-gray-500 hover:text-primary">
      <Link
        to={`/organizations/${organizationId}/vehicle-oems/${vehicleOemId}/device-oems/${deviceOemId}/${keyPath}/${
          isSet ? 'edit' : 'create'
        }`}
        className=""
      >
        <div className="flex flex-1 flex-row center justify-between items-center px-4 py-6">
          <div className="flex flex-1 flex-row center">
            <KeyIcon className="h-6 w-6 mr-4"></KeyIcon>
            <h2 className="">{keyName}</h2>
            <div className="ml-4 flex items-center">
              {isSet ? (
                <CheckIcon className="h-6 w-6 text-green-500"></CheckIcon>
              ) : (
                <p className="text-gray-400 text-sm">(Not Set)</p>
              )}
            </div>
          </div>

          <ChevronRightIcon className="h-6 w-6 text-gray-400 group-hover:text-primary"></ChevronRightIcon>
        </div>
      </Link>
    </div>
  )
}

export default KeyItem
