import { useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { sortBy } from 'lodash'
import { AsyncResourceContent } from 'use-async-resource'
import { useDeviceOemTokens, useDeviceOems } from '../lib/apiHooks'
import Error from '../components/Error'
import Loading from '../components/Loading'
import ButtonPrimary from '../components/ButtonPrimary'
import Section from '../components/Section'
import AdminHeader from '../components/AdminHeader'
import TokenList from '../components/TokenList'
import BreadCrumb from '../components/Breadcrumb'
import LoadingOverlay from '../components/LoadingOverlay'
import { clearDeviceOemTokens } from '../lib/apiHooks'

function DeviceOemTokensPage() {
  const { id, deviceOemId } = useParams()

  const [readTokens, refetchTokens] = useDeviceOemTokens(deviceOemId)
  const [readDeviceOems] = useDeviceOems()

  return (
    <div>
      <AdminHeader active="device-oems"></AdminHeader>
      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <DeviceOemTokens
            readTokens={readTokens}
            readDeviceOems={readDeviceOems}
            organizationId={id}
            deviceOemId={deviceOemId}
            refetchTokens={refetchTokens}
          ></DeviceOemTokens>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function DeviceOemTokens({
  readTokens,
  readDeviceOems,
  organizationId,
  deviceOemId,
  refetchTokens,
}) {
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const tokens = readTokens()
  const deviceOems = readDeviceOems()

  const deviceOem = deviceOems.find((d) => d.id === parseInt(deviceOemId, 10))

  const filteredTokens = sortBy(
    tokens.filter((t) => t.deviceOemId === parseInt(deviceOemId, 10)),
    (i) => i.createdTimestamp
  ).reverse()

  const breadcrumbItems = [
    {
      title: 'Device OEMs',
      url: `/device-oems`,
    },
    {
      title: deviceOem.cccId + ' - DKS Access Tokens',
    },
  ]

  const handleClick = () => {
    setWorking(true)
    axios
      .post(`/admin/api/v1/device-oem/${deviceOemId}/client-access-token`)
      .then(() => {
        clearDeviceOemTokens()
        refetchTokens(deviceOemId)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  return (
    <div>
      <LoadingOverlay visible={working}></LoadingOverlay>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb items={breadcrumbItems}></BreadCrumb>
        <ButtonPrimary
          onClick={handleClick}
          text={`Create Token`}
        ></ButtonPrimary>
      </div>
      <TokenList
        organizationId={organizationId}
        tokens={filteredTokens}
      ></TokenList>
      {error && <span className="text-sm text-red-400">{error}</span>}
    </div>
  )
}

export default DeviceOemTokensPage
