import { useHistory } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'

function AdminHeaderComponent(props) {
  return (
    <AsyncResourceContent
      fallback={
        <div className="m-6 text-gray-300 font-light text-lg">Loading...</div>
      }
      errorMessage={(error) => <div>{error.message}</div>}
    >
      <AdminHeader {...props}></AdminHeader>
    </AsyncResourceContent>
  )
}

function AdminHeader({ active }) {
  const history = useHistory()

  return (
    <div className="my-6 flex space-x-4">
      <span
        onClick={() => history.push(`/`)}
        className="text-gray-300 font-light text-lg hover:text-header-hover transition-colors cursor-pointer"
        style={{ color: active === 'organizations' ? 'white' : null }}
      >
        Organizations
      </span>
      <span
        onClick={() => history.push(`/device-oems`)}
        className="text-gray-300 font-light text-lg hover:text-header-hover transition-colors cursor-pointer"
        style={{ color: active === 'device-oems' ? 'white' : null }}
      >
        Device OEMs
      </span>
    </div>
  )
}

export default AdminHeaderComponent
