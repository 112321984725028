import TokenListItem from './TokenListItem'

function TokenList({ tokens }) {
  return (
    <section>
      {tokens.map((i) => (
        <TokenListItem key={i.id} token={i}></TokenListItem>
      ))}
      {tokens.length === 0 && <div className="p-6">No Tokens</div>}
    </section>
  )
}

export default TokenList
