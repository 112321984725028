import DeviceOemItem from './DeviceOemItem'

function DeviceOemList({
  organizationId,
  vehicleOemId,
  deviceOems,
  readVehicleOemKeys,
}) {
  return (
    <section>
      {deviceOems.map((i) => (
        <DeviceOemItem
          key={i.id}
          organizationId={organizationId}
          vehicleOemId={vehicleOemId}
          deviceOem={i}
          readVehicleOemKeys={readVehicleOemKeys}
        ></DeviceOemItem>
      ))}
      {deviceOems.length === 0 && <div className="p-6">No Device OEMs</div>}
    </section>
  )
}

export default DeviceOemList
