import React, { useContext, createContext, useState } from 'react'
import axios from 'axios'

const authContext = createContext()

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export function useAuth() {
  return useContext(authContext)
}

function useProvideAuth() {
  const [apiToken, setApiToken] = useState(localStorage.getItem('apiToken'))

  const login = (username, password) => {
    return axios
      .post('/admin/api/v1/session', {
        username,
        password,
      })
      .then((r) => {
        const { apiToken } = r.data

        localStorage.setItem('apiToken', apiToken)
        setApiToken(apiToken)
      })
  }

  const logout = () => {
    localStorage.removeItem('apiToken')
    window.location.reload()
  }

  return {
    apiToken,
    login,
    logout,
  }
}
