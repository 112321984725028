import LoadingOverlay from './LoadingOverlay'

function Loading() {
  return (
    <section className="p-6">
      <LoadingOverlay visible={true}></LoadingOverlay>
    </section>
  )
}

export default Loading
