import { RefreshIcon } from '@heroicons/react/outline'

function Loading({ visible }) {
  if (!visible) return null
  return (
    <div className="absolute left-0 top-0 right-0 bottom-0 bg-white justify-center items-center flex">
      <RefreshIcon className="h-12 w-12 text-gray-400 animate-spin"></RefreshIcon>
    </div>
  )
}

export default Loading
