import { Link } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import { useDeviceOems } from '../lib/apiHooks'
import { DeviceMobileIcon, ChevronRightIcon } from '@heroicons/react/outline'
import Error from '../components/Error'
import Loading from '../components/Loading'
import ButtonPrimary from '../components/ButtonPrimary'
import Section from '../components/Section'
import AdminHeader from '../components/AdminHeader'
import BreadCrumb from '../components/Breadcrumb'

function DeviceOemsPage() {
  const [readDeviceOems] = useDeviceOems()

  return (
    <div>
      <AdminHeader active="device-oems"></AdminHeader>
      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <DeviceOems readDeviceOems={readDeviceOems}></DeviceOems>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function DeviceOems({ readDeviceOems }) {
  const deviceOems = readDeviceOems()

  return (
    <>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb items={[{ title: 'Device OEMs' }]}></BreadCrumb>
        <Link to={`/device-oems/create`} className="">
          <ButtonPrimary text="New Device OEM"></ButtonPrimary>
        </Link>
      </div>
      <section>
        {deviceOems.map((deviceOem) => (
          <div
            key={deviceOem.cccId}
            className="cursor-pointer border-b last:border-b-0 group transition-colors text-gray-500 hover:text-primary"
          >
            <Link to={`/device-oems/${deviceOem.id}/tokens`} className="">
              <div className="flex flex-1 flex-row center justify-between items-center px-4 py-6">
                <div className="flex flex-1 flex-row center">
                  <DeviceMobileIcon className="h-6 w-6 mr-4"></DeviceMobileIcon>
                  <h2 className="">{deviceOem.cccId}</h2>
                </div>

                <ChevronRightIcon className="h-6 w-6 text-gray-400 group-hover:text-primary"></ChevronRightIcon>
              </div>
            </Link>
          </div>
        ))}
        {deviceOems.length === 0 && <div className="p-6">No Device OEMs</div>}
      </section>
    </>
  )
}

export default DeviceOemsPage
