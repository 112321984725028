import axios from 'axios'

export function fetchCurrentUser() {
  return axios.get(`/admin/api/v1/account`).then((r) => r.data)
}

export function fetchOrganization(id) {
  return axios.get(`/admin/api/v1/organization/${id}`).then((r) => r.data)
}

export function fetchOrganizations() {
  return axios.get(`/admin/api/v1/organization`).then((r) => r.data.items)
}

export function fetchOrganizationUsers(id) {
  return axios
    .get(`/admin/api/v1/organization/${id}/account`)
    .then((r) => r.data.items)
}

export function fetchOrganizationUser(organizationId, accountId) {
  return axios
    .get(`/admin/api/v1/organization/${organizationId}/account/${accountId}`)
    .then((r) => r.data)
}

export function fetchVehicleOems(id) {
  return axios
    .get(`/admin/api/v1/organization/${id}/vehicle-oem`)
    .then((r) => r.data.items)
}

export function fetchVehicleOem(id, vehicleOemId) {
  return axios
    .get(`/admin/api/v1/organization/${id}/vehicle-oem/${vehicleOemId}`)
    .then((r) => r.data)
}

export function fetchDeviceOems() {
  return axios.get(`/admin/api/v1/device-oem`).then((r) => r.data.items)
}

export function fetchVehicleOemKeys(vehicleOemId) {
  return Promise.all([
    axios.get(`/admin/api/v1/vehicle-oem/${vehicleOemId}/encryption-key`),
    axios.get(`/admin/api/v1/vehicle-oem/${vehicleOemId}/external-ca`),
    axios.get(`/admin/api/v1/vehicle-oem/${vehicleOemId}/signing-key`),
  ]).then(([encryptionKeys, externalCAs, signingKeys]) => ({
    encryptionKeys: encryptionKeys.data.items,
    externalCAs: externalCAs.data.items,
    signingKeys: signingKeys.data.items,
  }))
}

export function fetchVehicleOemTokens(vehicleOemId) {
  return axios
    .get(`/admin/api/v1/vehicle-oem/${vehicleOemId}/client-access-token`)
    .then((r) => r.data.items)
}

export function fetchDeviceOemTokens(deviceOemId) {
  return axios
    .get(`/admin/api/v1/device-oem/${deviceOemId}/client-access-token`)
    .then((r) => r.data.items)
}
