import { ChevronRightIcon, TruckIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

function VehicleOemItem({ organizationId, vehicleOem }) {
  return (
    <div className="cursor-pointer border-b last:border-b-0 group transition-colors text-gray-500 hover:text-primary">
      <Link
        to={`/organizations/${organizationId}/vehicle-oems/${vehicleOem.id}`}
        className=""
      >
        <div className="flex flex-1 flex-row center justify-between items-center px-4 py-6">
          <div className="flex flex-1 flex-row center">
            <TruckIcon className="h-6 w-6 mr-4"></TruckIcon>
            <h2 className="">{vehicleOem.cccId}</h2>
          </div>

          <ChevronRightIcon className="h-6 w-6 text-gray-400 group-hover:text-primary"></ChevronRightIcon>
        </div>
      </Link>
    </div>
  )
}

export default VehicleOemItem
