import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import SmallForm from '../components/SmallForm'
import ButtonPrimary from '../components/ButtonPrimary'
import ButtonCancel from '../components/ButtonCancel'
import Error from '../components/Error'
import Loading from '../components/Loading'
import LoadingOverlay from '../components/LoadingOverlay'
import { useVehicleOem, clearVehicleOemCache } from '../lib/apiHooks'

const schema = yup.object().shape({
  certificatePem: yup.string().required('Certificate is required'),
  secureKeyPem: yup.string().required('Secure Key is required'),
  publicKeyPem: yup.string().required('Public Key is required'),
  ktsAccessKey: yup.string().required('KTS Access Key is required'),
})

function UpdateVehicleOemPage() {
  const { id, vehicleOemId } = useParams()

  const [readVehicleOem] = useVehicleOem(id, vehicleOemId)

  return (
    <SmallForm>
      <AsyncResourceContent
        fallback={<Loading></Loading>}
        errorMessage={(error) => <Error error={error}></Error>}
      >
        <UpdateVehicleOem
          readVehicleOem={readVehicleOem}
          organizationId={id}
          vehicleOemId={vehicleOemId}
        ></UpdateVehicleOem>
      </AsyncResourceContent>
    </SmallForm>
  )
}

function UpdateVehicleOem({ readVehicleOem, organizationId, vehicleOemId }) {
  const vehicleOem = readVehicleOem()
  const history = useHistory()
  const [error, setError] = useState()
  const [working, setWorking] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    reset(vehicleOem)
  }, [vehicleOem, reset])

  const onSubmit = ({
    certificatePem,
    secureKeyPem,
    publicKeyPem,
    ktsAccessKey,
  }) => {
    setWorking(true)
    axios
      .put(
        `/admin/api/v1/organization/${organizationId}/vehicle-oem/${vehicleOemId}`,
        {
          certificatePem,
          secureKeyPem,
          publicKeyPem,
          ktsAccessKey,
        }
      )
      .then(() => {
        clearVehicleOemCache()
        history.push(`/organizations/${organizationId}`)
      })
      .catch((e) => {
        setError(e.message)
      })
      .finally(() => setWorking(false))
  }

  const cancel = (e) => {
    e.preventDefault()
    history.push(
      `/organizations/${organizationId}/vehicle-oems/${vehicleOemId}`
    )
  }

  return (
    <>
      <h1 className="text-2xl mb-8">Edit Vehicle OEM</h1>
      <form className="relative" onSubmit={handleSubmit(onSubmit)}>
        <LoadingOverlay visible={working}></LoadingOverlay>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Public Key</label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-24"
            {...register('publicKeyPem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.publicKeyPem?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">Secure Key</label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-36"
            {...register('secureKeyPem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.secureKeyPem?.message}
          </div>
        </div>
        <div className="mb-6">
          <label className="text-gray-500 text-sm mb-1 block">
            Certificate
          </label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-64"
            {...register('certificatePem')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.certificatePem?.message}
          </div>
        </div>
        <div className="">
          <label className="text-gray-500 text-sm mb-1 block">
            KTS Access Key
          </label>
          <textarea
            className="font-mono outline-none text-sm border border-gray-300 shadow-sm focus:border-primary appearance-none rounded w-full py-2 px-3 h-16"
            {...register('ktsAccessKey')}
          />
          <div className="text-sm text-red-400 mt-0">
            {errors.ktsAccessKey?.message}
          </div>
        </div>
        <div className="mt-8 space-x-4">
          <ButtonPrimary text="Save" type="submit"></ButtonPrimary>
          <ButtonCancel onClick={cancel}></ButtonCancel>
          {error && <span className="text-sm text-red-400">{error}</span>}
        </div>
      </form>
    </>
  )
}

export default UpdateVehicleOemPage
