import { Redirect } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import Organizations from './Organizations'
import { useCurrentUser } from '../lib/apiHooks'

function HomePage() {
  const [readCurrentUser] = useCurrentUser()

  return (
    <AsyncResourceContent
      fallback={<div className="">Loading...</div>}
      errorMessage={(error) => <div>{error.message}</div>}
    >
      <Home readCurrentUser={readCurrentUser}></Home>
    </AsyncResourceContent>
  )
}

function Home({ readCurrentUser }) {
  const user = readCurrentUser()

  if (!user.isAdmin) {
    return (
      <Redirect
        to={{ pathname: `/organizations/${user.organizationId}` }}
      ></Redirect>
    )
  }

  return <Organizations></Organizations>
}

export default HomePage
