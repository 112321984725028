import { useParams, Link } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import { useOrganization, useVehicleOems } from '../lib/apiHooks'
import Error from '../components/Error'
import Loading from '../components/Loading'
import ButtonPrimary from '../components/ButtonPrimary'
import Section from '../components/Section'
import VehicleOemList from '../components/VehicleOemList'
import OrganizationHeader from '../components/OrganizationHeader'
import BreadCrumb from '../components/Breadcrumb'

function OrganizationPage() {
  const { id } = useParams()

  const [readOrganizations] = useOrganization(id)
  const [readVehicleOems] = useVehicleOems(id)

  return (
    <div>
      <OrganizationHeader active="organization"></OrganizationHeader>
      <Section>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <Organization
            readOrganizations={readOrganizations}
            readVehicleOems={readVehicleOems}
            organizationId={id}
          ></Organization>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function Organization({ readOrganizations, readVehicleOems, organizationId }) {
  const organization = readOrganizations()
  const vehicleOems = readVehicleOems()

  return (
    <>
      <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
        <BreadCrumb
          items={[{ title: organization.name + ' - Vehicle OEMs' }]}
        ></BreadCrumb>
        <Link
          to={`/organizations/${organizationId}/vehicle-oems/create`}
          className=""
        >
          <ButtonPrimary text="New Vehicle OEM"></ButtonPrimary>
        </Link>
      </div>
      <VehicleOemList
        vehicleOems={vehicleOems}
        organizationId={organizationId}
      ></VehicleOemList>
    </>
  )
}

export default OrganizationPage
