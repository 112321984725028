import OrganizationItem from './OrganizationItem'

function OrganizationList({ organizations }) {
  return (
    <section>
      {organizations.map((i) => (
        <OrganizationItem key={i.id} organization={i}></OrganizationItem>
      ))}
      {organizations.length === 0 && (
        <div className="p-6">No Organizations</div>
      )}
    </section>
  )
}

export default OrganizationList
