import { useAsyncResource, resourceCache } from 'use-async-resource'
import {
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationUsers,
  fetchOrganizationUser,
  fetchCurrentUser,
  fetchVehicleOems,
  fetchVehicleOem,
  fetchDeviceOems,
  fetchVehicleOemKeys,
  fetchVehicleOemTokens,
  fetchDeviceOemTokens,
} from './api'

export function useCurrentUser() {
  return useAsyncResource(fetchCurrentUser, [])
}

export function useOrganization(id) {
  return useAsyncResource(fetchOrganization, id)
}

export function useOrganizations() {
  return useAsyncResource(fetchOrganizations, [])
}

export function useOrganizationUsers(id) {
  return useAsyncResource(fetchOrganizationUsers, id)
}

export function useOrganizationUser(organizationId, accountId) {
  return useAsyncResource(fetchOrganizationUser, organizationId, accountId)
}

export function useVehicleOems(id) {
  return useAsyncResource(fetchVehicleOems, id)
}

export function useVehicleOem(id, vehicleOemId) {
  return useAsyncResource(fetchVehicleOem, id, vehicleOemId)
}

export function useDeviceOems() {
  return useAsyncResource(fetchDeviceOems, [])
}

export function useVehicleOemKeys(vehicleOemId) {
  return useAsyncResource(fetchVehicleOemKeys, vehicleOemId)
}

export function useVehicleOemTokens(vehicleOemId) {
  return useAsyncResource(fetchVehicleOemTokens, vehicleOemId)
}

export function useDeviceOemTokens(deviceOemId) {
  return useAsyncResource(fetchDeviceOemTokens, deviceOemId)
}

// CACHE

export function clearOrganizationUserCache() {
  resourceCache(fetchOrganizationUser).clear()
}

export function clearOrganizationCache() {
  resourceCache(fetchOrganization).clear()
}

export function clearOrganizationsCache() {
  resourceCache(fetchOrganizations).clear()
}

export function clearOrganizationUsersCache() {
  resourceCache(fetchOrganizationUsers).clear()
}

export function clearVehicleOemCache() {
  resourceCache(fetchVehicleOems).clear()
}

export function clearDeviceOemCache() {
  resourceCache(fetchDeviceOems).clear()
}

export function clearVehicleOemKeysCache() {
  resourceCache(fetchVehicleOemKeys).clear()
}

export function clearVehicleOemTokens() {
  resourceCache(fetchVehicleOemTokens).clear()
}

export function clearDeviceOemTokens() {
  resourceCache(fetchDeviceOemTokens).clear()
}
