import { Link } from 'react-router-dom'
import { AsyncResourceContent } from 'use-async-resource'
import Error from '../components/Error'
import Loading from '../components/Loading'
import OrganizationList from '../components/OrganizationList'
import AdminHeader from '../components/AdminHeader'
import ButtonPrimary from '../components/ButtonPrimary'
import BreadCrumb from '../components/Breadcrumb'
import Section from '../components/Section'
import { useOrganizations } from '../lib/apiHooks'

function OrganizationsPage() {
  const [readOrganizations] = useOrganizations()

  return (
    <div>
      <AdminHeader active="organizations"></AdminHeader>
      <Section>
        <div className="flex flex-1 flex-row center justify-between items-center border-b pb-6">
          <BreadCrumb items={[{ title: 'Organizations' }]}></BreadCrumb>
          <Link to={`/organizations/create`} className="">
            <ButtonPrimary text="New Organization"></ButtonPrimary>
          </Link>
        </div>
        <AsyncResourceContent
          fallback={<Loading></Loading>}
          errorMessage={(error) => <Error error={error}></Error>}
        >
          <Organizations readOrganizations={readOrganizations}></Organizations>
        </AsyncResourceContent>
      </Section>
    </div>
  )
}

function Organizations({ readOrganizations }) {
  const organizations = readOrganizations()
  return <OrganizationList organizations={organizations}></OrganizationList>
}

export default OrganizationsPage
