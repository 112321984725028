function ButtonCancel({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="outline-none focus:outline-none focus:border-primary active:border-primary hover:text-primary transition-colors 
        font-normal py-2 px-8 rounded border border-gray-300 shadow-sm"
    >
      Cancel
    </button>
  )
}

export default ButtonCancel
